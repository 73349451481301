<template>
  <section class="cases">
    <v-container  grid-list-md text-center id="news_top" style="margin-bottom:10px">
      <template>
        <div>
          <v-breadcrumbs :items="breadcrumbs" divider=" / "></v-breadcrumbs>
        </div>
      </template>
      <v-container
        fluid
        grid-list-sm
        mt-4
        mb-12
      >
        <v-layout wrap v-if="detail" class="text-left">
          <v-flex xs12 sm12 md4 lg4>
            <v-img
              :src="detail.img"
              aspect-ratio="1"
            ></v-img>
          </v-flex>
          <v-flex xs12 sm12 md8 lg8 pl-10>
            <h3 style="color:#333; font-size:32px;">{{detail.title}}</h3>
            <ul v-if="detail.attr" style="list-style: none; padding:0px;">
              <li v-for="(attr, i) in detail.attr" :key="i" style="margin: 20px 0px;">
                <span style="color:#666; font-size:16px;margin-right:60px;">{{attr.attr_name}}:</span>
                <span style="color:#333; font-size:16px;">{{attr.attr_value}}</span>
              </li>
            </ul>
          </v-flex>
          <v-flex mt-4>
            <div  v-html="detail.content" class="ctk-news-content">
              {{detail.content}}
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 3,
    locale: null,
    category: null,
    detail: null,
    breadcrumbs: []
  }),
  created () {
    document.querySelector('#menu_case').classList.add('v-btn--active')
    this.locale = localStorage.getItem('locale')
    this.getCategory()
    this.page = 1
  },
  watch: {
    page: {
      handler: function (val, oldVal) {
        this.getCases(this.$route.params.id, val)
      },
      deep: true
    },
    category: {
      handler: function (val, oldVal) {
        this.parseBreadcrumbs(val)
      },
      deep: true
    },
    detail: {
      handler: function (val, oldVal) {
        this.breadcrumbs.push({ text: val.title, disabled: true, href: '' })
      },
      deep: true
    }
  },
  methods: {
    getCategory () {
      this.https.get('case_category', { cid: this.$route.params.id }).then(response => {
        if (response.code === 0) {
          this.category = response.data
          this.getCase()
        }
      })
    },
    getCase () {
      this.https.get('case', { id: this.$route.params.id }).then(response => {
        if (response.code === 0) {
          this.detail = response.data
        }
      })
    },
    parseBreadcrumbs (category) {
      this.breadcrumbs.push({ text: this.$t('cases.name'), disabled: false, href: '/cases' })
      let cateName = 'name'
      if (this.locale !== 'cn' && this.locale !== null) {
        cateName = cateName + '_' + this.locale
      }
      if (category.pcat) {
        this.breadcrumbs.push({ text: category.pcat[cateName], disabled: false, href: '/cases/' + category.pcat.id })
      }
      if (category.cat) {
        this.breadcrumbs.push({ text: category.cat[cateName], disabled: false, href: '/cases/' + category.pcat.id + '/' + category.cat.id })
      }
    }
  }
}
</script>
